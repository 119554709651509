<template>
    <v-dialog v-model="modalData.dialog" max-width="450px" persistent scrollable>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="permissionCan('create')"
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="$emit('new')"
            >
              {{ $t("MENU.NEW") }} {{ $t("MENU.bannerCampaign") }}
            </v-btn>
        </template>
        
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <span class="headline">{{ formTitle }} </span>
                      <v-btn @click="handleCloseModalForm" icon class="close-button">
                            <v-icon> mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            
            <v-card-text>
                <v-form v-model="formValid" ref="form">
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                v-model="formModel.name"
                                :label="$t('FORM_INPUT_NAMES.name')"
                                :id="dynamicID"
                                :rules="requiredRules"
                                :error-messages="messages['name']"
                                @keyup="messages['name'] = ''"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-select
                              v-model="formModel.banner_client_id"
                              :items="bannerClientCollection"
                              :label="$t('FORMS.banner_client')"
                              item-text="name"
                              item-value="id"
                              :rules="requiredRules"
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            
            <v-card-actions class="mb-2">
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="handleCloseModalForm">
                  {{ $t("FORMS.cancel") }}
                </v-btn>
                <v-btn
                  v-if="
                    (permissionCan('create') && !formModel.id) ||
                    permissionCan('update')
                  "
                  color="primary"
                  elevation="2"
                  @click="handleSaveModalForm"
                >
                  {{ $t("FORMS.save") }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-overlay :value="loader">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { PERMISSION_TO } from "./BannerCampaigns";
import ApiService from "@/core/services/api.service";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
    
export const initialFormData = () => ({
    id: null,
    banner_client_id: null,
    name: "",
});

export default {
    name: "BannerCampaignForm",  
    props: ["modalData", "permissions", "endPoint"],
    mixins: [formModelMixins],
    data() {
        return {
            result: null,
            formModel: Object.assign({}, initialFormData()),
            formValid: false,
            permissionTo: PERMISSION_TO,
            messages: {},
            nameRules: [
                (v) => !!v || this.$t("ERRORS.field_is_required"),
                (v) =>
                  (!!v && v.length < 181) || this.$t("ERRORS.must_be_max_characters"),
            ],
            requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
            
        };           
    },
    computed: {
        ...mapGetters(["bannerCampaignCollection","bannerClientCollection"]),
        dynamicID() {
            let text = "dynamicID";
            let chars = "abcdefghijklmnopqrstuvwxyz";
            for (let i = 0; i < 10; i++) {
              text += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return text;
        },
        formTitle() {
            return this.formModel.id
              ? this.$t("FORMS.edit") + this.formModel.name
              : this.$t("MENU.NEW") + " " + this.$t("MENU.bannerCampaign");
        },
        initialFormData() {
            return initialFormData;
        },
    },
    watch: {
        modalData: {
            deep: true,
            handler(value) {
                if (value.editedId) {
                    this.loader = true;
                    this.formModel = Object.assign({}, initialFormData());
                    ApiService.get(this.endPoint + value.editedId)
                        .then(({ data }) => {
                          this.formModel = Object.assign({}, data);
                        })
                        .catch((error) => {
                          console.log("Error!: ", error);
                        })
                        .finally(() => {
                          this.loader = false;
                          this.$refs.form.resetValidation();
                        });
                }
                else {
                    this.formModel = Object.assign({}, initialFormData());
                    if (this.$refs.form) {
                        this.$refs.form.resetValidation();
                    }      
                }
                this.resetErrorMessages();
            }
        }
    },
    methods: {
        ...mapActions(["fetchBannerCampaign","fetchBannerClient"]),
        handleSaveModalForm() {
            let model = Object.assign({}, this.formModel);
            this.$refs.form.validate();
            if (this.formValid) {
                this.resetErrorMessages();
                this.loader = true;
                if (model.id) {
                    ApiService.put(this.endPoint + model.id, model)
                        .then(() => {
                            this.$emit("saveModalForm");
                        })
                        .catch((error) => {
                            if (error.response) {
                                let errors = error.response.data;
                                if (errors) {
                                    for (let field in errors.errors) {
                                      this.setError(field, errors.errors[field][0]);
                                    }
                                }
                            }
                        })
                        .finally(() => {
                            this.loader = false;
                        });
                  } else {
                    //create model
                    ApiService.post(this.endPoint, model)
                        .then(() => {
                            this.$emit("saveModalForm");
                        })
                        .catch((error) => {
                            if (error.response) {
                                let errors = error.response.data;
                                if (errors) {
                                    for (let field in errors.errors) {
                                        this.setError(field, errors.errors[field][0]);
                                    }
                                }
                            } else if (error.request) {
                              // The request was made but no response was received
                                console.log(error.request);
                            } else {
                              // Something happened in setting up the request that triggered an Error
                                console.log("Error", error.message);
                            }
                            console.log("Error!: ", error);
                            // this.modalData.loading = false;
                        })
                        .finally(() => {
                          this.loader = false;
                        });
                  }
            }
        }
    },
    
    mounted() {
        this.fetchBannerCampaign();
        this.fetchBannerClient();
    },
}
</script>